<template>
    <div>
        <input id="search_input" class="main_input" type="text" v-model="search_input" :placeholder="info ? info : 'Поиск'" @keyup="searchTimeOut()" @keyup.enter="redirect"/>
        <div v-if="preloader">
            <div v-if="list && list.length">
                <div v-for="(item, index) in list.slice(0, 5)" :key="index">
                    <router-link :to="{name: cat[item.type].cat,params: { id:item.id }}">
                        <div class="search_plagin_item" v-html="item.title"></div>
                    </router-link>
                </div>
                <div v-if="list.length > 5">
                    <router-link :to="{name: 'Search',params: { search_text:search_input }}">
                        <div class="all_result">Посмотреть все результаты ({{this.list.length}})</div>
                    </router-link>
                </div>
            </div>
            <div v-if="alert" class="search_plagin_item">
                Извините, Мы не смогли ничего найти
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        cat_search: String,
        info: String
    },
    name: "SearchPlagin",
    components: {},
    data() {
        return {
            preloader: 'finish',
            timer: 0,
            limit: 20,
            list: [],
            search_input: null,
            alert: false,
            cat: {
                'Shares': {
                    cat: 'Shares',
                    fullname: 'Акции'
                },
                'News': {
                    cat: 'News',
                    fullname: 'Новости'
                },
                'Organizations': {
                    cat: 'OrganisationGet',
                    fullname: 'Организации'
                },
                'Poster': {
                    cat: 'Post',
                    fullname: 'Афиша'
                }
            },
        }
    },
    methods: {
        api_search: function() {
            this.preloader = null;
            this.cat_status = null;
            let url = this.$config.api_url + this.$route.params.city + '/Search.form';
            this.$http.post(url, {
                query: this.search_input,
                type: this.cat_search,
                limit: 50
            }).then((response) => {
                this.list = response.data.response;
            }).finally(() => {
                if (this.list && this.list.length) {
                    this.alert = false;
                } else {
                    this.alert = true;
                }
                this.preloader = 'finish';
            })

        },
        searchTimeOut() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if (this.search_input && this.search_input.length >= 3) {
                    this.api_search();

                }
            }, 500);
        },
        redirect() {
            this.$router.push({name:'Search', params: { search_text: this.search_input }});
        }

    }

}
</script>
<style scoped>
@media (min-width: 768px) {
    .search_plagin_item:hover {
        background: rgba(74, 118, 168, .05);
        transition: .3s;
    }

    .all_result {
        background: rgba(74, 118, 168, .05);
        transition: .3s;
    }

}

a,
a:hover {
    color: #2c3e50;
}

.search_plagin_item {
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 20px;
    text-align: left;
}

.search_plagin_date {
    width: 100%;
    text-align: right;
}

.all_result {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 20px;
    background: #4a76a8;
    color: #fff;
}
</style>