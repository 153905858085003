<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <b-col class="poster_header">
                    <h1>Акции</h1>
                </b-col>
                <div v-if="preLoad">
                    <SearchPlagin cat_search="Shares" info="Поиск в акциях"/>
                    <div class="mobile_filter" v-if="$mobileWidth()">
                        <b-button v-b-toggle:my-collapse>
                            <div class="right_block_child_title">КАТЕГОРИИ</div>
                        </b-button>
                        <b-collapse id="my-collapse">
                            <div class="right_block_child">
                                <div v-if="category">
                                    <div class="right_block_child_item" @click="api_new()">Все категории</div>
                                    <div v-for="item in category" :key="item.id" class="right_block_child_item" @click="api_new(item.id, 'newcategory')">{{ item.title }}</div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <article v-for="(item, num) in list" :key="num">
                        <b-card no-body class="poster_card overflow-hidden" >
                            <router-link :to="{name:'Shares',params:{id:item.id}}">
                                <b-row no-gutters>
                                    <b-col md="4" class="align-self-center">
                                        <b-card-img :src="item.image" class="rounded-0"></b-card-img>
                                    </b-col>
                                    <b-col md="8" class="poster_body">
                                        <b-card-body>
                                            <h3 class="poster_title">{{item.name}}</h3>
                                            <b-card-text class="poster_text" v-html="cutText(item.description, limit)"></b-card-text>
                                            <b-col cols="12" class="poster_footer mt-2" v-if="item.at">
                                                <b-row>
                                                    <b-col cols="12" class="poster_date" v-if="$moment(item.start_at).format('DD.MM.YY') == $moment(item.end_at).format('DD.MM.YY')" v-html="$moment(item.start_at).format('DD.MM.YY HH:mm')"></b-col>
                                                    <b-col cols="12" class="poster_date" v-else-if="item.start_at && item.end_at" v-html="$moment(item.start_at).format('DD.MM.YYYY') + ' - ' + $moment(item.end_at).format('DD.MM.YYYY')"></b-col>
                                                    <b-col cols="12" class="poster_date" v-else-if="item.start_at" v-html="$moment(item.start_at).format('DD.MM.YY HH:mm')"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-card-body>
                                    </b-col>
                                </b-row>
                            </router-link>
                        </b-card>
                        <AdsPlaginNew v-if="num !== 0 && num % 3 === 0" :direction="'horizontal'"/>
                    </article>
                    <div v-if="button_preloader">
                        <button v-if="page < totalPages" class="load_next_button" @click="api_next()">Показать ещё</button>
                    </div>
                    <div v-else>
                        <PreLoadBox />
                    </div>
                </div>
                <div v-else>
                    <PreLoadBox></PreLoadBox>
                </div>
            </b-col>
            <div class="right_block" v-if="!$mobileWidth()">
                <div class="right_block_child">
                    <div class="right_block_child_title">КАТЕГОРИИ</div>
                    <div v-if="category">
                        <div class="right_block_child_item" @click="api_new()">Все категории</div>
                        <div v-for="item in category" :key="item.id" class="right_block_child_item" @click="api_new(item.id, 'newcategory')">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </b-row>
    </div>
</template>
<script>
/* eslint-disable no-console */
import SearchPlagin from '@/components/Plagins/SearchPlagin';
import PreLoadBox from "../../Preloader/coin";

export default {
    name: "PostList",
    components: {
        PreLoadBox,
        SearchPlagin,
    },
    metaInfo() {
        return {
            title: this.list && this.list.length ? `Акции и скидки - ` : null,
            meta: [
                {name: 'description', itemprop: 'description', content: `Акции, скидки, каталоги продуктовых супермаркетов, магазинов электроники и бытовой техники, стройматериалов, одежды и обуви в городе ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Акции и скидки - ${this.$myCity.name}`},
                {property: 'og:description', content: `Акции, скидки, каталоги продуктовых супермаркетов, магазинов электроники и бытовой техники, стройматериалов, одежды и обуви в городе ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            button_preloader: 'finish',
            category: [],
            list: [],
            limit: 120,
            cat_search: 'Shares',
            page: 1,
            totalPages: null,
            cat_id: null
        }
    },
    methods: {
        category_api() {
            let url = this.$config.api_url + this.$route.params.city + '/Shares.getList';
            this.$http.get(url).then((response) => {
                this.category = response.data.response.category;
            })
        },
        api_new(id, newcategory) {
            if(newcategory) {
                this.cat_id = id;
            }
            this.page = 1;     
            id = id ? id : null;
            this.$scrollToTop();
            this.preLoad = null;
            let url = this.$config.api_url + this.$route.params.city + '/Shares.getList';
            this.$http.get(url, {
                params: {
                    page: this.page,
                    category_id: id
                }
            }).then((response) => {
                this.totalPages = response.data.response.last_page;
                this.list = response.data.response.data;
            }).catch(error => {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: error,
                    title: 'Ошибка!',
                    duration: 5000,
                });
            }).finally(() => {
                this.preLoad = 'finish';
            });
        },
        api_next() {    
            let id = this.cat_id ? this.cat_id : null;
            this.button_preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Shares.getList';
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    category_id: id
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.response.data);
            }).catch(error => {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: error,
                    title: 'Ошибка!',
                    duration: 5000,
                });
            }).finally(() => {
                this.button_preloader = 'finish';
            });
        },
        cutText(value, limit) {
            return value.length > limit ?
                value.slice(0, limit - 3) + '...' :
                value;
        }
    },
    mounted() {
        this.$scrollToTop();
        this.api_new();
        this.category_api();
    }
}
</script>
<style scoped>
@media only screen and (max-width: 991px) {}

@media only screen and (min-width: 768px) {
    .poster_text {
        margin-bottom: 37px;
    }

    .poster_footer {
        padding: 5px 0 5px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

    }
}

.con a {
    text-decoration: none;
    color: #fff;
}

.poster_header {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;

}

.poster_back {
    width: 75px;
    padding: 5px 5px;
    position: relative;
    background: #4a76a8;
    border-radius: 0.25rem;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
}

.poster_back:before {
    content: "";
    border: solid transparent;
    position: absolute;
    top: 5px;
    right: 100%;
    border-right-color: #4a76a8;
    border-width: 7px;
    margin-right: -1px;
}

.poster_back a {
    text-decoration: none;
    color: #fff;
}

.poster_card {
    margin-bottom: 10px;
    border-radius: 12px;
}

.poster_card:hover {
    background: #4a76a8;
    color: #fff;
    transition: .5s;
}

.poster_card:hover .poster_title {
    color: #fff;
    transition: .5s;
}

.poster_card:hover .poster_footer {
    color: #fff;
    transition: .5s;
}

.poster_card:hover .poster_text {
    color: #fff;
    transition: .5s;
}

.poster_card a {
    text-decoration: none;
    color: #2c3e50;
}

.poster_title {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    text-decoration: none;

}

.poster_text {
    text-align: left;
    font-size: 12px;

}

.card-body {
    padding: 10px;
}

.poster_footer {
    padding: 2px 5px 2px 0;
    border-top: 1px solid #d8d8d8;
}

.poster_date {
    text-align: right;
    font-size: 13px;
}
</style>